.payment-component .text-info {
  font-size: 13px;
  margin: 10px 0 0;
}
.payment-component .btn {
  border-radius: 20px;
  font-size: 13px;
  appearance: none;
}
.payment-component .error {
  margin-top: 8px;
  font-size: 14px;
  color: red;
}
.payment-component .preloader {
  margin-left: 10px;
}
.payment-component .btn.cancel {
  margin-left: 10px;
  border-radius: 20px;
  font-size: 14px;
}
.payment-component .result {
  font-size: 14px;
}

.modal-dialog.payment-component {
  max-width: 530px;
}
